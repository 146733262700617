/* 

@font-face {
  font-family: Regular;
  src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeui.ttf") format("truetype");
}

@font-face {
  font-family: Bold;
  src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuib.ttf") format("truetype");
}

@font-face { font-family: Light; src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuil.ttf") format("truetype"); }

@font-face {
  font-family: SemiBold;
  src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/seguisb.ttf") format("truetype");
}

body {
  margin: 0;
  font-family:"Regular", "sans-serif"
}

:root{
  --base-font-color: var(--secondaryColor)
}
 */


#root {
  height: 100vh;
}

::-webkit-scrollbar {
  background-color: transparent;
}

.container:before,
.container:after {
  display: table;
  content: "";
}

.container:after {
  clear: both;
}

.container:before,
.container:after {
  display: table;
  content: "";
}

.container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: auto;
  margin: auto 4% !important;
}

/* React-select */

.rs-selectall-btn {
  padding: 0;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  margin-right: 7px;
}

.rs-option-checkbox-cn {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.rs-option-label-cn,
.re-multivalue-cn {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.report .se-sidenav-container .se-side-navbar-hamburger {
  top: 17px;
}

.report-list .se-sidenav-container .se-side-navbar-hamburger {
  top: 25px;
}

.report-list.expand .se-report-header-label {
  margin-left: 0;
}

.report.collapse .se-header-container {
  margin-left: 0;
}

.se-report-card-tooltip {
  max-width: 293px;
  box-shadow: 0px 3px 4px var(--boxShadow);
  background: var(--backgroundColor);
}


.se-non-row-sort .rc-tooltip-content {
  display: none;
}

.ag-tooltip {
  font-family: "Regular", "sans-serif";
  min-height: 22px !important;
  padding: 9px 12px !important; 
  text-align: left;
  -webkit-text-decoration: none;
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid var(--backgroundColor) !important;
  box-shadow: 0px 3px 4px var(--boxShadow);
  color: var(--white) !important;
  background-color: var(--primaryColor) !important;
}